<template>
  <div>
    <section style="background-color: var(--primary-dark);" class="selfcare-title d-flex">
      
    </section>

    <div style="background-color: var(--primary-dark);" class="promotions">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-12 mb-4" v-for="(promotion, index) in promotions" :key="index">
            <div style="background-color: var(--darkerBackground);" class="card">
              <img style="border-bottom-left-radius: 7px;border-bottom-right-radius: 7px;" :src="`/img/carousel/bahabanners/` + promotion.img" class="card-img-top img-fluid" alt="Promotion image">
              <!-- <div class="card-body">
                <h5 class="card-title text-light">{{ promotion.title }}</h5>
                <p class="card-text text-light">{{ promotion.description }}</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Offers',
  data() {
    return {
      promotions: [
        {
          img: "appbonus.jpg",
          link: "/register",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "bahatika.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "deposit.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "freebet.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "jackpot.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "karibu.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "referal.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
        {
          img: "stake.jpg",
          link: "/deposit",
          title: "PROMOS TITLE",
          description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam consequatur necessitatibus eaque",
        },
      ],
    };
  },
  components: {},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "offers");
    this.reloadProfile();

  }

}
</script>
